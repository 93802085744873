<template>
		<div class="video-container">
			
			<div
				v-if="contentData && contentData.data && contentData.data.status && contentData.data.status.toLowerCase() == 'processing'"
				class="video-processing"
			>
				<i class="fas fa-cogs"></i>
				Video is currently processing. The video will be available soon...
			</div>
			<div
				v-if="contentData && contentData.data && contentData.data.status && contentData.data.status.toLowerCase() == 'notuploaded'"
				class="video-processing"
			>
				<i class="fas fa-exclamation-triangle"></i>
				The video is either corrupted or incorrectly uploaded. Please inform
				your trainer or the administrator.
			</div>
			<iframe
				allow="encrypted-media; fullscreen"
				v-else-if="contentData && contentData.data && (contentData.data.viewUrl || contentData.data.url)"
				:src="viewUrl"
				class="video-player"
			></iframe>
		</div>
</template>

<script>
 import { mapActions, mapMutations, mapState } from 'vuex';

export default {
	name: "RecordedVideo",
	data() {
		return {
			ended: false,
			isCompleted: false,
		}
	},
	methods: {
		...mapActions("content", ["setUserContentAction","getUserContentAction","updateAction"]),
		...mapActions("productVariant", ["getProductVariantProgress"]),

	// 	markCompleted(isCompleted) {
    //   if (this.contentData.id) {
    //     var contentAction = {
    //       ContentID: this.contentData.id,
    //       DataType: "completionStatus",
    //       Data: isCompleted ? "completed" : "uncompleted"
    //     }
		
    //     this.setUserContentAction(contentAction).then((action) => {
    //       if (action && action.dataType && action.dataType.toLowerCase() == "completionstatus") {
			
	// 		this.isCompleted = action.data === 'completed';
	// 		this.updateAction(action);

    //         this.getProductVariantProgress(this.$route.params.id);
    //       }

    //     });
    //   }
    // },
	checkVideoEndedEvent(event){
		let ended = event && event.data && event.data.data && event.data.data.ended;
		if(ended){
			console.log("Video Ended: "+ended)
		}
		return ended
	},
	getCompletedStatus(){
				if(this.contentData.id)
				{
					this.getUserContentAction({contentId: this.contentData.id, dataType:"completionStatus"}).then((userActions)=>{
						var userAction = userActions?userActions.find(x=>x.dataType=="completionStatus"):null;
						if(userAction)
						{
              				this.isCompleted = userAction.data === 'completed';
						}
					});
				}
			},
		
	},
	props: {
		contentData: Object,
	},
	watch:{
		ended: function(){
			// if(this.ended && !this.isCompleted){
			// 	this.markCompleted(this.ended)
			// }
		}
	},
	computed: {
		...mapState("content", ["contentActions"]),
		viewUrl(){
			if(this.contentData && this.contentData.externalService && this.contentData.externalService.serviceProvider && this.contentData.externalService.serviceProvider.toLowerCase() == 'youtube')
			{
				return this.contentData.data.url;
			}
			
			var lastPlaybackTimeInSeconds = (this.contentActions && this.contentActions[this.contentData.id])?
			this.contentActions[this.contentData.id].find(x=>x.dataType.toLowerCase()=="lastplaybacktimeinseconds"):
			null;
			if(lastPlaybackTimeInSeconds)
			{
				return this.contentData.data.viewUrl+`&playback=`+lastPlaybackTimeInSeconds?.data;
			}
			return this.contentData.data.viewUrl;
		}
	},
	created() {
		this.getCompletedStatus();

		var self=this;
		window.addEventListener("message", 
		function (event) {
			self.ended = self.checkVideoEndedEvent(event)

		}, false);
	},
};
</script>

<style scoped>
.video-container {
	display: flex;
	height:100%;
}

.video-player {
	width: 100%;
}

.video-processing {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	background-color: #fafafa;
	font-size: 1.5rem;
	font-weight: 500;
	color: #999;
	row-gap: 1rem;
	padding: 0 1rem;
	text-align: center;
}

.video-processing .fas {
	font-size: 3rem;
}



@media (max-width:768px){

	.video-container{
		height: calc(100vh - 248px);
		background-color: #000;
	}
	
}
</style>
